
.message-box {
    position: fixed;
    top: 40px;
    left:auto;
    right: 5px;
    width: 80%;
    z-index: 2000;
    box-sizing: border-box;
}

.iti { width: 100%; }
